<template>
  <div class="register-company app_content" v-show="showPage">
    <div class="warp_body">
      <div class="registerBody">
        <van-form @submit="onSubmit">
          <van-cell-group inset>
            <!-- 输入任意文本 -->
            <van-field v-model="form.companyName" required label="用工单位名称" label-width="105px" placeholder="请输入用工单位名称"
                       :rules="[{ validator: validatorCompanyMessage }]"/>
            <!-- 输入手机号，调起手机号键盘 -->
            <van-field v-model="form.creditCode" type="text" label-width="105px" required label="社会信用代码"
                       placeholder="请输入社会信用代码" :rules="[{ validator: validatorCreditCodeMessage }]"/>

            <van-field
                v-model="form.areaText"
                required
                is-link
                readonly
                name="picker"
                label="单位地域"
                placeholder="点击选择区域"
                @click="getAreaList"
                :rules="[{ validator: validatorAreaMessage }]"
            />
            <van-popup v-model:show="showAreaPicker" position="bottom">
              <van-picker
                  :columns="areaList"
                  @confirm="onConfirmArea"
                  @cancel="showAreaPicker = false"
              />
            </van-popup>
            <van-field v-model="form.companyAddress" rows="1"
                       autosize
                       required
                       maxlength="100"
                       type="textarea" label="详细地址"
                       placeholder="请输入用工单位详细地址" :rules="[{ validator: validatorCompanyAddressMessage }]"/>

            <van-field name="certificationPic" required label-width="100px"
                       label="营业执照上传"
                       :rules="[{ validator: validatorLicenseImgMessage }]">
              <template #input>
                <van-uploader v-model="fileListLicense" :after-read="afterReadLicenceImg" max-count="1"
                              :before-delete="beforeDeleteLicenceImg"/>
              </template>
            </van-field>
            <verification-code
                @changeVerificationCode="changeVerificationCode" :str="phoneLabel"
                @changePhone="changePhone"></verification-code>


          </van-cell-group>
          <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit">
              注册
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, ref, toRaw} from 'vue';
import {onBeforeMount, reactive, toRefs, nextTick, onActivated, onDeactivated} from 'vue'
import {Toast} from 'vant'
import {useRouter} from 'vue-router'
import req from "../request/request";
import API from "../api/User";
import router from "../router";
import verificationCode from '../components/common/verificationCode'
import {compress} from "_image-conversion@2.1.1@image-conversion";

export default {
  name: 'RegisterCompany',
  components: {
    verificationCode
  },

  setup() {

    onMounted(() => {
      let registerType = JSON.parse(sessionStorage.getItem("registerType"))
      let pathRoute = useRouter();
      console.log(pathRoute.currentRoute)
      console.log(pathRoute.currentRoute.value.path)
      if (registerType.route != pathRoute.currentRoute.value.path) {
        data.showPage = false
        Toast("不合法的访问！")
        router.push("/")
      }
    });

    const data = reactive({
          showPage: true,
          phoneLabel: '单位电话',
          showAreaPicker: false,
          fileListLicense: [],
          areaList: [],
          form: {
            areaId: '',
            area: '',
            areaText: '',
            creditCode: '',
            companyName: '',
            companyAddress: '',
            companyPhone: '',
            verificationCode: '',
            licenseImg: '',
            openid: localStorage.getItem("openid")
          }
        },
    )

    const methodsMap = {
      validatorCompanyMessage: (val) => {
        if (!val) {
          return '请输入单位名称'
        }
      },
      validatorLicenseImgMessage: () => {
        if (data.fileListLicense.length == 0) {
          return '请上传资质凭证'
        }
      },
      validatorAreaMessage: (val) => {
        if (!val) {
          return '请选择所在区域'
        }
      },
      validatorCreditCodeMessage: (val) => {
        if (!val) {
          return '请输入信用代码'
        }
      },
      validatorCompanyAddressMessage: (val) => {
        if (!val) {
          return '请输入用工单位详细地址'
        }
      },
      onConfirmArea: (item) => {
        data.showAreaPicker = false
        data.form.areaText = item.text
        data.form.area = item.value
        data.form.areaId = item.id
      },
      onSubmit: (form) => {
        console.log('submit', form)
        let params = data.form
        req.request(API.register + "/" + JSON.parse(sessionStorage.getItem("registerType")).code, params,
            {method: 'POST_FORMDATA'}, {message: '注册中...'})
            .then((res) => {
              if (res.data.code == 200) {
                router.push('/GetUserInfo')
                Toast.success("注册成功！")
              }
            })

      },

      getAreaList: () => {
        data.showAreaPicker = true
        req.request(API.areaList, null, {method: 'GET'})
            .then((res) => {
              data.areaList = res.data.data
            })
      },
      afterReadLicenceImg: (file) => {
        console.log('sss', data.fileListLicense[0].file)
        // data.form.licenseImg = data.fileListLicense[0].file
        new Promise((resolve) => {
          compress(file.file, 0.6)
              .then(res => {
                console.log(res)
                data.form.licenseImg = new window.File([res], file.file.name, {type: file.file.type})
                // new window.File([resolve(res),file.file.name,{type:file.file.type}])
              });
        })
      },
      beforeDeleteLicenceImg: () => {
        data.fileListLicense = []
        data.form.licenseImg = ''
      },
      changeVerificationCode: (val) => {
        data.form.verificationCode = val
      },
      changePhone: (val) => {
        console.log("data.form.companyPhone:", val)
        data.form.companyPhone = val
      }
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less">
.registerBody {
  margin-top: 5px;
}
</style>
